import script from "./PropertyListItem.vue?vue&type=script&lang=ts&setup=true"
export * from "./PropertyListItem.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QItem,QItemSection});
