import moment, { ISO_8601 } from "moment";

export function formatDateTime(val: string): string {
    if (val === null) return '';

    const timeZoneRegex = /[Zz]|([+|-][0-9]{2}:[0-9]{2})/g
    if (!val.match(timeZoneRegex))
        val = val+ "Z";
    return moment(val, ISO_8601, true).format("YYYY/MM/DD HH:mm:ss");
    
}
export function formatPercentage(val: number | null): string {
    if (val === null)
    return '';
    return `${val} %`;
}
export function formatTicksToDuration(val: number | null) : string {
    if (val === null)
    return '';

        // The value is 
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
    const leadingZero = (num: number) => zeroPad(num, 2);

    const duration = moment.duration(val * 1000);
    const totalDays =  Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    
    const timePart =  `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`;
    if (totalDays < 1)
        return timePart


    return `${totalDays}d ${timePart}`

    
}