import { AttachmentDto } from "@/store/Dtos/ProductionOrders";
import axios from "axios";

const regex = /^(https?:\/\/)?([\w.-]*)(:\d+)?(:\/[.]*)?/;
if (window.location.href.match(regex) === null)
  throw new Error("Can't determine path");
const match = window.location.href.match(regex);

if (match === null)
  throw new Error("Could not find base url from uri");
const apiUrl = match[0].toString() + "/machine/api";
const apiUrlDev: string = process.env.VUE_APP_API;

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? apiUrl : apiUrlDev
});

class ApiService {
  async downloadDocument(
    attachment: AttachmentDto
  ) {
    const response = await api.get(
      `${attachment.id}/download-foto`,
      {
        params: { documentNaam: attachment.attachmentName },
        responseType: 'blob'
      }
    );

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', attachment.attachmentName);
    document.body.appendChild(fileLink);

    fileLink.click();
    return response.data;
  }
}

export const apiService = new ApiService()