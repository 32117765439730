export enum CombinatorTypeEnum {
  Initial = 10,
  Not = 11,
  And = 20,
  AndNot = 21,
  Or = 30,
  OrNot = 31,
}

export const SingleCombinatorTypeEnums = [CombinatorTypeEnum.Initial, CombinatorTypeEnum.Not];
export const MultipleCombinatorTypeEnums = [CombinatorTypeEnum.And, CombinatorTypeEnum.AndNot, CombinatorTypeEnum.Or, CombinatorTypeEnum.OrNot];

export enum FilterOperatorEnum {
  Equals = 10,
  NotEquals = 11,
  GreaterThan = 20,
  GreaterThanOrEqual = 21,
  LessThan = 22,
  LessThanOrEqual = 23,
  Contains = 30,
  StartsWith = 31,
  EndsWith = 32,
  Previous = 40,
  Next = 41,
}
export const FilterOperatorsForNumbers = [
  FilterOperatorEnum.Equals,
  FilterOperatorEnum.NotEquals,
  FilterOperatorEnum.GreaterThan,
  FilterOperatorEnum.GreaterThanOrEqual,
  FilterOperatorEnum.LessThan,
  FilterOperatorEnum.LessThanOrEqual
];
export const FilterOperatorsForStrings = [
  FilterOperatorEnum.Contains,
  FilterOperatorEnum.StartsWith,
  FilterOperatorEnum.EndsWith,
]
export const FilterOperatorsForEnums = [
  FilterOperatorEnum.Equals,
  FilterOperatorEnum.NotEquals
]
export const FilterOperatorsForDates = [
  FilterOperatorEnum.Equals,
  FilterOperatorEnum.NotEquals,
  FilterOperatorEnum.GreaterThan,
  FilterOperatorEnum.GreaterThanOrEqual,
  FilterOperatorEnum.LessThan,
  FilterOperatorEnum.LessThanOrEqual
]

export interface AddNodeEventArgs {
  parentIds: number[];
  firstInTreeLevel: boolean;
}
export interface RemoveNodeEventArgs {
  idToRemove: number;
  parentIds: number[];
}
export interface GroupChangedEventArgs {
  groupId: number;
  newCombinator: number;
  parentIds: number[];
}

export interface FilterChangedEventArgs {
  filterId: number;
  newOperator: number;
  newCombinator: number;
  newFilterProperty: string;
  newFilterValue: number | string;
  parentIds: number[];
}



export interface ExpressionFilter {
  propertyName: string;
  value: number | string;
  operator: FilterOperatorEnum;
  //opties: Option[] | null;
}

// export interface Option {
//   id: string;
//   naam: string;
// }

export interface ExpressionFilterNode {
  id: number;
  combinator: CombinatorTypeEnum;
  treeLevel: number;
  filter: ExpressionFilter;
}

export interface AdvancedFilterOption {
  id: string;
  name: string;
  type: AdvancedFilterOptionTypeEnum;
}
export interface ExpressionStructureNode {
  id: number;
  combinator: CombinatorTypeEnum;
  treeLevel: number;
  childExpressionNodes: (ExpressionFilterNode | ExpressionStructureNode)[];
}



export enum AdvancedFilterOptionTypeEnum {
//  Unsupported = "unsupported",
  String = "string",
  Number = "number",
  Enum = "enum",
  Timespan = "timespan",
  Date = "date",
  Boolean = "boolean",
}



// export enum SingleCombinatorTypeDisplayEnum {
//   "Where" = CombinatorTypeEnum.Initial,
//   "Where not" = CombinatorTypeEnum.Not,
// }

// export enum MultipleCombinatorTypeDisplayEnum {
//   "And" = CombinatorTypeEnum.And,
//   "And not" = CombinatorTypeEnum.AndNot,
//   "Or" = CombinatorTypeEnum.Or,
//   "Or not" = CombinatorTypeEnum.OrNot,
// }
