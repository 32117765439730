import { ILanguage } from "./interfaces/ILanguage";

export const defaultLanguage = {
    applyPredefinedFilter: "Apply predefined filter",
    selectPredefinedFilter: "Select predefined filter",

    applyManualFilter: "Apply manual filter",
    filters: "Filters",
    columns: "Columns",
    sorting: "Sorting",
    saveAsPredefinedFilter: "Save as predefined filter",
    addPredefinedFilter: "Add predefined filter",

    filterConditions: "Filter conditions",
    deleteGroup: "Delete group",
    filter: "Filter",
    group: "Group",

    selectAndOrderColumns: "Select and order columns",
    columnIsMandatory: "Column is mandatory",
    useThisColumnOrdering: "Use this column ordering",
    addExtraordering: "Add extra ordering",
    firstBy: "First by",
    thenBy: "then by",
    column: "Column",
    delete: "Delete",

    nameForPredefinedFilter: "Predefined filter name",
    save: "Save",
    cancel: "Cancel"
} as ILanguage;
