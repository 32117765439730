export interface ISortingLanguage {
    useThisColumnOrdering: string;
    addExtraordering: string;
    firstBy: string;
    thenBy: string;
    delete: string;
    ascending: string;
    descending: string;
}

export const sortingLanguage : ISortingLanguage = {
    useThisColumnOrdering: "Use this column order",
    addExtraordering: "Add extra ordering",
    firstBy: "First by",
    thenBy: "Then by",
    delete: "Delete",
    ascending: "Ascending",
    descending: "Descending"
}