export enum SortingOrderEnum {
    Descending = 10,
    Ascending = 20
}

export enum SortingOrderDisplayEnum {
    'Descending' = SortingOrderEnum.Descending,
    'Ascending' = SortingOrderEnum.Ascending
}

export interface SortingExpression {
    id: number;
    propertyName: string | null;
    order: SortingOrderEnum;
}

export interface AdvancedSortingOption {
    id: string;
    naam: string;
    isDefault?: boolean;
    defaultSorting?: SortingOrderEnum;
}
