import { CombinatorTypeEnum, FilterOperatorEnum, SelectOption } from "../models";
import { IFilterLanguage } from "./language";

export function combinatorTypeToOptionsPool(src: CombinatorTypeEnum[], lang: IFilterLanguage) : SelectOption[] {
    return src.map((value) => {
        return {
            label: lang.combinatorDescription(value),
            value
        } as SelectOption
    });
}

export function filterOperatorToOptionsPool(src: FilterOperatorEnum[], translator: (input: FilterOperatorEnum) => string) : SelectOption[] {
    return src.map((value) => {
        return {
            label: translator(value),
            value
        } as SelectOption
    });
}