import script from "./BaseTableServerSide.vue?vue&type=script&lang=ts&setup=true"
export * from "./BaseTableServerSide.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QInput,QIcon,QSelect,QBtn,QTooltip,QTd});
