import script from "./StructureNode.vue?vue&type=script&lang=ts&setup=true"
export * from "./StructureNode.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip});
