import { SortingOrderEnum } from "./sorting";
import { AdvancedFilterOptionTypeEnum } from "./filter";
import { TableColumn } from "./helpers";

export interface AdvancedQueryOptionDto {
  id: string;
  naam: string;
}

export interface AdvancedQueryDto {
  id: string;
  naam: string;
  type: number;
  filter: string;
  columns: string;
  order: string;
}

export interface AdvancedQueryAddDto {
  naam: string;
  type: string;
  filter: string;
  columns: TableColumn[];
  order: string;
  creator: string;
}

export interface AdvancedQueryEditDto {
  id: string;
  naam: string;
  type: number;
  filter: string;
  columns: string;
  order: string;
}

export enum AdvancedQueryTypeEnum {
  "Projecten" = 10,
  "Werkorders" = 20,
  "Taken" = 30,
}

export interface AdvancedQueryRequest {
  queryId: string | null;
  page?: number;
  pageSize?: number;
  orderBy?: string | null;
  descending?: boolean | null;
  filter?: string | null;
  filterColumns?: string[] | null;
  clientFilter: string | null;
}

export interface AdvancedQueryColumnConfig {
  name: string; //verplicht     naam property in dto
  label: string; //verplicht     zichtbare weergave, display
  type: AdvancedFilterOptionTypeEnum; //verplicht     string date number enum
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enum?: any; //deze gebruiken als type enum is, enkel als enum als nummer in DB zit
  defaultVisibleColumn: boolean; //verplicht
  requiredColumn?: boolean; //bij filter niet mogelijk of wel mogelijk om uit te vinken
  sortableColumn?: boolean; //of je kan sorteren
  columnTextAlign?: ColumnTextAlignEnum; //center links ?
  defaultOrderByColumn?: boolean; //best maar één op default order inschakelen
  defaultSortingOrder?: SortingOrderEnum; //asc desc
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?(val: any): any; //functie uitgevoerd voor weergave, om weergave aan te passen
}


export interface AdvancedFilterExportSettings {
  controllerURL: string;
  preFilter: string | null;
}

export enum ColumnTextAlignEnum {
  Left = "left",
  Right = "right",
  Center = "center",
}
