import {
  ColumnInfo,
  QueryResult,
  TableColumn,
  XOrder,
  XPagination,
  AdvancedQueryAddDto,
  AdvancedQueryDto,
  AdvancedQueryEditDto,
  AdvancedQueryOptionDto,
  AdvancedQueryRequest,
} from "../models";

// Interfaces
import { IAlertService } from "../interfaces/IAlertService";
import { AxiosInstance } from "axios";

const url = "/advancedQueries";

export class AdvancedQueryService {
  // Fields
  notificationService: IAlertService;
  axios: AxiosInstance;

  constructor(notificationService: IAlertService, axios: AxiosInstance) {
    this.notificationService = notificationService;
    this.axios = axios;
  }

  async getQueryResult(
    controllerUrl: string,
    request: AdvancedQueryRequest
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<QueryResult<any[]>> {

    const response = await this.axios.get(`${controllerUrl}/projections`, {
      params: request
        ? {
          query: request.queryId ? request.queryId : null,
          page: request.page,
          pageSize: request.pageSize,
          orderBy: request.orderBy,
          descending: request.descending,
          filter: request.filter,
          filterColumns: request.filterColumns
            ? request.filterColumns.join(",")
            : null,
        }
        : null,
    });

    let queryColumnInfo = JSON.parse(response.headers["x-columns"] as string);

    queryColumnInfo = JSON.parse(queryColumnInfo) as TableColumn[];

    if (queryColumnInfo && request.filterColumns) {
      queryColumnInfo = queryColumnInfo.map((ci: TableColumn) => {
        ci.selected =
          (request.filterColumns as string[]).findIndex(
            (fc) => ci.name == fc
          ) > -1;
        return ci;
      });
    }

    return {
      pagination: JSON.parse(response.headers["x-pagination"]) as XPagination,
      columnInfo: queryColumnInfo,
      orderInfo: JSON.parse(response.headers["x-order"]) as XOrder,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: response.data as any[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as QueryResult<any[]>;

  }

  async getQueryResultExport(
    controllerUrl: string,
    request: AdvancedQueryRequest,
    endPointUrl?: string,
    title?: string
  ): Promise<void> {

    const url = `${controllerUrl}/${endPointUrl ? endPointUrl : 'export'}`;
    const response = await this.axios.get(url, {
      params: request
        ? {
          query: request.queryId ? request.queryId : null,
          page: request.page,
          pageSize: request.pageSize,
          orderBy: request.orderBy,
          descending: request.descending,
          filter: request.filter,
          filterColumns: request.filterColumns
            ? request.filterColumns.join(',')
            : null
        }
        : null,
      responseType: 'blob'
    });

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    const bestelbonFileNaam = `${title} export`;
    fileLink.setAttribute('download', `${bestelbonFileNaam}.xlsx`);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  mergeColumnInfo(columns: ColumnInfo[], columnOpties: TableColumn[]): TableColumn[] {
    const result = columns.map((c) => {
      const tableColumn = columnOpties.find((o) => o.name == c.name);

      const result = { ...tableColumn, selected: c.selected } as TableColumn;

      return result;
    });

    return result;
  }

  getColumnInfo(chosenColumns: TableColumn[]): ColumnInfo[] {
    return chosenColumns.map((c) => {
      return { selected: c.selected, name: c.name } as ColumnInfo;
    });
  }

  async getAdvancedQueryOptions(creator: string, type: number): Promise<AdvancedQueryOptionDto[]> {
    try {
      const response = await this.axios.get(`${url}/options`, {
        params: { type: type, creator: creator },
      });
      return response.data as AdvancedQueryOptionDto[];
    } catch (error) {
      this.notificationService.error(
        "Advanced query opties laden is mislukt"
      );
      return [];
    }
  }
  async getAdvancedQuery(queryId: string): Promise<AdvancedQueryDto> {
    try {
      const response = await this.axios.get(`${url}/${queryId}`);

      return response.data as AdvancedQueryDto;
    } catch (error) {
      this.notificationService.error(
        "Advanced query laden is mislukt"
      );
      throw error;
    }
  }

  async postAdvancedQuery(advancedQuery: AdvancedQueryAddDto): Promise<string> {
    try {
      const { data } = await this.axios.post<string>(`${url}`, {
        columns: JSON.stringify(advancedQuery.columns),
        creator: advancedQuery.creator,
        filter: advancedQuery.filter,
        naam: advancedQuery.naam,
        order: advancedQuery.order,
        type: advancedQuery.type
      });
      return data;
    } catch (error) {
      this.notificationService.error(
        "Advanced query aanmaken is mislukt"
      );
      return "";
    }
  }

  async postManualAdvancedQuery(advancedQuery: AdvancedQueryAddDto): Promise<string> {
    try {
      const response = await this.axios.post(`${url}/manual`, advancedQuery);
      return response.data as string;
    } catch (error) {
      return "";
    }
  }

  async editAdvancedQuery(advancedQuery: AdvancedQueryEditDto): Promise<AdvancedQueryDto> {
    try {
      const response = await this.axios.put(
        `${url}/${advancedQuery.id}`,
        advancedQuery
      );
      this.notificationService.info("Opgeslagen");

      return response.data as AdvancedQueryDto;
    } catch (error) {
      this.notificationService.error(
        "Advanced query bewerken is mislukt"
      );
      throw error;
    }
  }

  async deleteAdvancedQuery(id: string): Promise<boolean> {
    try {
      await this.axios.delete(`${url}/${id}`);
      this.notificationService.info(
        "Filter werd verwijderd"
      );
      return true;
    } catch (error) {
      return false;
    }
  }
}
