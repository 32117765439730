const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_WORKDAY = 24 * SECONDS_IN_HOUR;
const SECONDS_IN_WORKWEEK = 7 * SECONDS_IN_WORKDAY;

const regexpFull = /([-]?)(\b(\d+)([.,](\d+))?\s?([wdhm])\s*)*/i;
const regexpParts = /([-]?)(\b(\d+)([.,](\d+))?\s?([wdhm])\s*)/gi;

export function secondsToTimespanString (seconds: number): string {
    let workWeeks = 0;
    let remainderAfterWorkWeeks = 0;
    let workDays = 0;
    let remainderAfterWorkDays = 0;
    let hours = 0;
    let remainderAfterHours = 0;
    let minutes = 0;
    let timeSpanString = '';

    if (seconds == undefined) {
        return 'none';
    }

    if (seconds == 0) {
        return '0h';
    }

    if (seconds % (SECONDS_IN_WORKWEEK / 2) == 0) {
        workWeeks = seconds / SECONDS_IN_WORKWEEK;
        remainderAfterWorkWeeks = 0;
    } else {
        workWeeks = Math.floor(seconds / SECONDS_IN_WORKWEEK);
        remainderAfterWorkWeeks = seconds % SECONDS_IN_WORKWEEK;
    }
    if (workWeeks > 0) {
        timeSpanString += `${workWeeks}w `;
    }

    if (remainderAfterWorkWeeks > 0) {
        if (remainderAfterWorkWeeks % (SECONDS_IN_WORKDAY / 2) == 0) {
            workDays = remainderAfterWorkWeeks / SECONDS_IN_WORKDAY;
            remainderAfterWorkDays = 0;
        } else {
            workDays = Math.floor(
                remainderAfterWorkWeeks / SECONDS_IN_WORKDAY
            );
            remainderAfterWorkDays =
                remainderAfterWorkWeeks % SECONDS_IN_WORKDAY;
        }
        if (workDays > 0) {
            timeSpanString += `${workDays}d `;
        }
    }

    if (remainderAfterWorkDays > 0) {
        if (remainderAfterWorkDays % (SECONDS_IN_HOUR / 2) == 0) {
            hours = remainderAfterWorkDays / SECONDS_IN_HOUR;
            remainderAfterHours = 0;
        } else {
            hours = Math.floor(remainderAfterWorkDays / SECONDS_IN_HOUR);
            remainderAfterHours = remainderAfterWorkDays % SECONDS_IN_HOUR;
        }
        if (hours > 0) {
            timeSpanString += `${hours}h `;
        }
    }

    if (remainderAfterHours > 0) {
        minutes = remainderAfterHours / SECONDS_IN_MINUTE;
        if (minutes > 0) {
            timeSpanString += `${minutes}m`;
        }
    }

    return timeSpanString.trim();
}

export function secondsToHoursString(seconds: number): string  {
    let hours = 0;
    let remainderAfterHours = 0;
    let minutes = 0;
    let timeSpanString = '';

    if (seconds == undefined) {
        return 'none';
    }

    if (seconds == 0) {
        return '0h';
    }

    if (seconds > 0) {
        if (seconds % (SECONDS_IN_HOUR / 2) == 0) {
            hours = seconds / SECONDS_IN_HOUR;
            remainderAfterHours = 0;
        } else {
            hours = Math.floor(seconds / SECONDS_IN_HOUR);
            remainderAfterHours = seconds % SECONDS_IN_HOUR;
        }
        if (hours > 0) {
            timeSpanString += `${hours}h `;
        }
    }

    if (remainderAfterHours > 0) {
        minutes = remainderAfterHours / SECONDS_IN_MINUTE;
        if (minutes > 0) {
            timeSpanString += `${minutes}m`;
        }
    }

    return timeSpanString.trim();
}

function timespan(value: string) {
    const match = value.match(regexpFull);
    return (
        (match && match[0] == value) || 'No valid time, check info'
    );
}

export function convertToSeconds(value: string, allowNegative = true): number {
    if (timespan(value) === true) {
        const matches = [...(value).matchAll(regexpParts)];
        return matches.reduce((c, m) => matchToSeconds(m, c, allowNegative), 0);
    }
    return 0;
}

function matchToSeconds(
    match: RegExpMatchArray,
    currentValue: number,
    allowNegative: boolean
) {
    const isNegative = match[1] === '-' && allowNegative === true;
    const multiplier = isNegative ? -1 : 1;
    const integer = isNaN(parseInt(match[3])) ? 0 : parseInt(match[3]);
    const decimals = isNaN(parseInt(match[5])) ? 0 : parseFloat(`0.${match[5]}`);
    const amount = integer + decimals;
    switch (match[6].toLowerCase()) {
        case 'w':
            return currentValue + amount * SECONDS_IN_WORKWEEK * multiplier;
        case 'd':
            return currentValue + amount * SECONDS_IN_WORKDAY * multiplier;
        case 'h':
            return currentValue + amount * SECONDS_IN_HOUR * multiplier;
        case 'm':
            return currentValue + amount * SECONDS_IN_MINUTE * multiplier;
        default:
            return currentValue;
    }
}