import { CombinatorTypeEnum, FilterOperatorEnum } from "../models"

export interface IFilterLanguage {
    timespanFilter: {
        clickToShowInfo: string,
        enterDuration: string,
        validFormats: string,
        tooltipDescription: string,
        tooltipUnits: string,
        tooltipExamples: string,
    },
    removeFilter: string,
    combinatorDescription(src: CombinatorTypeEnum): string,
    filterOperatorNumberDescription(src: FilterOperatorEnum): string,
    filterOperatorTextDescription(src: FilterOperatorEnum): string,
    filterOperatorEnumDescription(src: FilterOperatorEnum): string,
    filterOperatorDatesDescription(src: FilterOperatorEnum): string,
    filterConditions: string,
    filter: string,
    group: string,
    removeGroup: string,
}

export const filterLanguage: IFilterLanguage = {
    timespanFilter: {
        clickToShowInfo: "Click to show info",
        enterDuration: "Enter duration",
        validFormats: "Valid formats",
        tooltipDescription: "A duration is entered as [<i>number</i> ][ <i>unit of time</i> ] and all its combinations (separated by <b>space</b>). The possible units of time are:",
        tooltipUnits: "<b>w</b> (week), <b>d</b> (day),<b>h</b> (hour), <b>m</b> (minute)",
        tooltipExamples: "<i>Examples <br /><span>3h 15m = 3 hours and 15 minutes</span><br /><span>1.5d = 1.5 days = 36 hours</span><br /><span>3w 2d = 3 weeks and 2 days = 23 days = 552 hours</span><br /><span>1.5w = 1.5 weeks = 10.5 days = 252 hours</span><br /></i>",
    },
    removeFilter: "Remove filter",
    combinatorDescription: (src: CombinatorTypeEnum) => {
        switch (src) {
            case CombinatorTypeEnum.And: return "And";
            case CombinatorTypeEnum.AndNot: return "And not";
            case CombinatorTypeEnum.Initial: return "Initial";
            case CombinatorTypeEnum.Not: return "Not";
            case CombinatorTypeEnum.Or: return "Or";
            case CombinatorTypeEnum.OrNot: return "Or not";
            default: return "Unkown";
        }
    },
    filterOperatorNumberDescription: (src: FilterOperatorEnum) => {
        switch (src) {
            case FilterOperatorEnum.Equals: return "=";
            case FilterOperatorEnum.NotEquals: return "!=";
            case FilterOperatorEnum.GreaterThan: return ">";
            case FilterOperatorEnum.GreaterThanOrEqual: return ">=";
            case FilterOperatorEnum.LessThan: return "<";
            case FilterOperatorEnum.LessThanOrEqual: return "<=";
            default: return "Unknown"
        }
    },
    filterOperatorTextDescription: (src: FilterOperatorEnum) => {
        switch (src) {
            case FilterOperatorEnum.Contains: return "contains";
            case FilterOperatorEnum.StartsWith: return "starts with";
            case FilterOperatorEnum.EndsWith: return "ends with";
            default: return "Unknown"
        }
    },
    filterOperatorEnumDescription: (src: FilterOperatorEnum) => {
        switch (src) {
            case FilterOperatorEnum.Equals: return "equals";
            case FilterOperatorEnum.NotEquals: return "not equals";
            default: return "Unknown"
        }
    },
    filterOperatorDatesDescription: (src: FilterOperatorEnum) => {
        switch (src) {
            case FilterOperatorEnum.Equals: return "equals";
            case FilterOperatorEnum.NotEquals: return "not equals";
            case FilterOperatorEnum.GreaterThan: return "after";
            case FilterOperatorEnum.GreaterThanOrEqual: return "after or equal to";
            case FilterOperatorEnum.LessThan: return "before";
            case FilterOperatorEnum.LessThanOrEqual: return "before or equal to";
            default: return "Unknown"
        }
    },
    filterConditions: "Filter conditions",
    filter: "Filter",
    group: "Group",
    removeGroup: "Remove group",
}