import script from "./FilterTimespan.vue?vue&type=script&lang=ts&setup=true"
export * from "./FilterTimespan.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QTooltip,QPopupProxy,QCard,QItem,QItemSection,QAvatar,QItemLabel});
