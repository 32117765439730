import script from "./ColumnSelector.vue?vue&type=script&lang=ts&setup=true"
export * from "./ColumnSelector.vue?vue&type=script&lang=ts&setup=true"

import "./ColumnSelector.vue?vue&type=style&index=0&id=e3e51c2e&lang=scss"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QCheckbox,QTooltip,QItemLabel,QIcon});
